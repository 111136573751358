import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { PATPalette } from '../../../themes/palette';
import { Icon } from '../../atoms/Icon/Icon';

const Background = styled('div')(() => ({
    top: 0,
    height: '100%',
    width: '100%',
    position: 'fixed',
    background: 'rgba(191,191,191,0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 101
}));

const Card = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '40px',
    background: PATPalette.neutral.white,
    position: 'absolute',
    maxWidth: '525px',
    minWidth: '425px'
}));
const CardHeader = styled('div')(() => ({
    display: 'flex',
    verticalAlign: 'baseline',
    marginTop: '16px',
    '> #warning': {
        marginBottom: '24px',
        color: PATPalette.secondary.orange[300],
        width: '28px',
        height: '28px'
    },
    '> #logout': {
        marginBottom: '24px',
        color: PATPalette.primary.skyBlue,
        width: '28px',
        height: '28px'
    }
}));

const CloseButton = styled('button')(() => ({
    width: '48px',
    height: '48px',
    cursor: 'pointer',
    background: 'transparent',
    border: 'none',
    position: 'absolute',
    top: 4,
    right: 4
}));

const Title = styled('p')(() => ({
    all: 'unset',
    color: PATPalette.neutral.black,
    fontFamily: 'TT Commons Pro',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    marginLeft: '8px'
}));

const Content = styled('p')(() => ({
    all: 'unset',
    color: PATPalette.neutral.grey[500],
    fontFamily: 'TT Commons Pro',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.16px',
    marginBottom: '18px'
}));

const ButtonsContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: '29px',
    justifyContent: 'flex-end'
}));

const Button = styled('button')<{ color: string }>(({ color }) => ({
    cursor: 'pointer',
    background: 'transparent',
    border: 'none',
    color,
    fontFamily: 'TT Commons Pro',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '18px',
    textDecorationLine: 'underline',
    padding: 0,
    margin: 0,
    textUnderlineOffset: '4px'
}));

type AlertType = 'default' | 'confirmation';

export type AlertDialogProps = {
    open: boolean;
    content: string;
    title: string;
    handleClose: () => void;
    type?: AlertType;
    icon?: string;
} & (ConfirmationType | DefaultType);

interface ConfirmationType {
    confirmButtonLabel?: string;
    cancelButtonLabel?: string;
    onCancellation?: () => void;
    onConfirmation?: () => void;
}

interface DefaultType {
    width?: string;
}

export const AlertDialog = ({
    content,
    handleClose,
    open = false,
    title,
    type = 'default',
    icon = 'warning',
    confirmButtonLabel = 'Confirm',
    cancelButtonLabel = 'Cancel',
    onConfirmation,
    onCancellation
}: AlertDialogProps & ConfirmationType) => {
    if (!open) {
        return null;
    }

    return (
        <Background>
            <Card>
                {type === 'default' && (
                    <CloseButton type="button" onClick={handleClose}>
                        <CloseIcon color="inherit" />
                    </CloseButton>
                )}
                <CardHeader>
                    <Icon id={icon} />
                    {!!title && <Title>{title}</Title>}
                </CardHeader>

                <Content>{content}</Content>

                {type === 'confirmation' && (
                    <ButtonsContainer>
                        <Button
                            type="button"
                            color={PATPalette.neutral.grey[400]}
                            onClick={onCancellation}
                            data-testid="timeout-signout-button"
                        >
                            {cancelButtonLabel}
                        </Button>

                        <Button
                            type="button"
                            color={PATPalette.primary.skyBlue}
                            onClick={onConfirmation}
                            data-testid="stay-signin-button"
                        >
                            {confirmButtonLabel}
                        </Button>
                    </ButtonsContainer>
                )}
            </Card>
        </Background>
    );
};
