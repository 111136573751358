import { Navigate, Route, Routes } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { memo } from 'react';
import NotFound from '../pages/404';
import Clients from '../pages/Clients';
import ClientsTest from '../pages/CreateClient';
import ClientJourney from '../pages/ClientJourney';
import Admin from '../pages/Admin';
import { useAppSelector } from '../common/hooks';
import Login from '../pages/Login';
import { ForgotPassword } from '../components/organisms';
import { RequiredAuth } from './SecureRoute';
import FinancialAdvisor from '../pages/FinancialAdvisor';
import { User } from '../pages/User';
import Resources from '../pages/Resources';

const AppRoutes = () => {
    const { authState } = useOktaAuth();
    const isAuthenticated = authState?.isAuthenticated;
    const authLoading = useAppSelector((state) => state.auth.loading);

    return (
        <Routes>
            <Route path="/" element={isAuthenticated && !authLoading ? <Navigate to="/clients" /> : <Login />} />
            <Route
                path="/forgot-password"
                element={isAuthenticated && !authLoading ? <Navigate to="/clients" /> : <ForgotPassword />}
            />

            <Route path="/add-user" element={<RequiredAuth />}>
                <Route path="" element={<FinancialAdvisor />} />
            </Route>
            <Route element={<RequiredAuth />}>
                <Route path="/resources" element={<Resources />} />
            </Route>
            <Route path="/clients" element={<RequiredAuth />}>
                <Route path="" element={<Clients />} />
            </Route>
            <Route path="/createclient" element={<RequiredAuth />}>
                <Route path="" element={<ClientJourney />} />
            </Route>
            <Route path="/creategoals" element={<RequiredAuth />}>
                <Route path="" element={<ClientJourney />} />
            </Route>
            <Route path="/reviewplan" element={<RequiredAuth />}>
                <Route path="" element={<ClientJourney />} />
            </Route>
            <Route path="/sourceIncome" element={<RequiredAuth />}>
                <Route path="" element={<ClientsTest />} />
            </Route>
            <Route path="/admin" element={<RequiredAuth />}>
                <Route path="" element={<Admin />} />
            </Route>
            <Route path="/user" element={<RequiredAuth />}>
                <Route path="" element={<User />} />
            </Route>
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

export default memo(AppRoutes);
