import { AddOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { RelationshipType } from 'common/types';
import { IconButton, Avatar, Typography } from '../../atoms';
import { PATPalette, customPalette } from '../../../themes/palette';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

export interface RelationshipTreeProps {
    relationships?: Array<RelationshipType>;
    /* eslint-disable */
    onSubmit?(): void;
    /* eslint-enable */
    loggedInUserName?: string;
}

export const RelationshipTree = ({ relationships, onSubmit, loggedInUserName }: RelationshipTreeProps) => {
    // All css fixed values are referred from figma https://www.figma.com/file/dhQYwcwv9l2B6E4Z006vAy/Explorations
    const { t } = useTranslation();
    const StyledRelTreeContainer = styled('div')(() => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: relationships?.length === 3 ? '74px 0' : '20px 0'
    }));
    const StyledFlexRelTreeContainer = styled('div')(() => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        flex: '1 0 0',
        alignSelf: 'stretch',
        flexWrap: 'wrap',
        ...(relationships?.length === 4 && { maxWidth: '500px' })
    }));
    const StyledAddRelGroup = styled('div')(() => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '31px'
    }));
    const StyledRelGroup = styled('div')(() => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: relationships && relationships.length > 2 ? '199px' : '179px',
        height: relationships && relationships.length > 2 ? '102px' : '190px',
        borderRadius: '12px',
        border: `1px solid ${customPalette.customGray[93]}`,
        background: PATPalette.neutral.white
    }));
    const StyledRelName = styled(Typography)(() => ({
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '20px',
        margin: 0,
        marginTop: relationships && relationships.length > 2 ? '2.5px' : '15px',
        textAlign: 'center',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%'
    }));
    const StyledRelationTypography = styled(Typography)(({ theme }) => ({
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '16px',
        letterSpacing: '0.12px',
        margin: 0,
        color: theme.palette.grey[300]
    }));
    const StyledRelLinkedText = styled(Typography)(({ theme }) => ({
        fontWeight: '600',
        color: loggedInUserName ? PATPalette.primary.skyBlue : theme.palette.grey[300],
        paddingBottom: '0',
        marginBottom: '8px',
        marginTop: theme.spacing(2),
        borderBottom: loggedInUserName
            ? `1px solid ${PATPalette.primary.skyBlue}`
            : `1px solid ${theme.palette.grey[300]}`,
        lineHeight: theme.spacing(2.5),
        cursor: 'pointer'
    }));
    const StyledRelSubHeading = styled(Typography)(({ theme }) => ({
        lineHeight: theme.spacing(2.5),
        color: PATPalette.neutral.grey[400],
        fontWeight: '500',
        fontSize: 16,
        margin: 0
    }));
    const StyledIconButton = styled(IconButton)(({ theme }) => ({
        border: loggedInUserName ? `1px solid ${PATPalette.primary.skyBlue}` : `1px solid ${theme.palette.grey[300]}`,
        width: theme.spacing(6.2),
        height: theme.spacing(6.2),
        color: theme.palette.grey[300]
    }));
    const StyledAddOutlined = styled(AddOutlined)(({ theme }) => ({
        width: theme.spacing(3.6),
        height: theme.spacing(3.6),
        color: loggedInUserName ? PATPalette.primary.skyBlue : theme.palette.grey[300]
    }));

    const StyledRelBox = styled(Box)(() => ({
        width: '100%',
        padding: '0 32px'
    }));
    const StyledPrimaryRelation = styled(Typography)(({ theme }) => ({
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '16px',
        letterSpacing: '0.12px',
        margin: 0,
        color: theme.palette.primary.main
    }));

    // Commented for future purpose incase of multiple relationships
    // const StyledIconContainer = styled(IconButton)(() => ({
    //     color: PATPalette.primary.skyBlue,
    //     border: `2px solid ${PATPalette.primary.skyBlue}`,
    //     marginTop: '12px',
    //     size: 'small'
    // }));
    // const StyledAddRelationshipTypography = styled(Typography)(() => ({
    //     fontSize: '16px',
    //     fontWeight: 600,
    //     lineHeight: '20px',
    //     margin: 0,
    //     marginTop: '15px'
    // }));
    // const StyledRelation = styled(Typography)(() => ({
    //     fontSize: '12px',
    //     fontWeight: 600,
    //     lineHeight: '16px',
    //     letterSpacing: '0.12px',
    //     margin: 0,
    //     color: PATPalette.secondary.lightBlue
    // }));
    // const StyledRelationLine = styled('div')(() => ({
    //     borderBottom: `1px solid ${PATPalette.customGray[85]}`
    // }));
    // const StyledRelConnector = styled('div')(() => ({
    //     width: '84px'
    // }));
    if (relationships && relationships.length > 0)
        return (
            <StyledRelTreeContainer>
                {relationships.length === 2 ? (
                    <>
                        {/* Commented for future purpose incase of multiple relationships */}
                        {/* <StyledRelGroup>
                            <Avatar name={relationships[0].firstName} />
                            <StyledRelBox>
                                <StyledRelName
                                    label={
                                        loggedInUserName || relationships[0].firstName + ' ' + relationships[0].lastName
                                    }
                                />
                            </StyledRelBox>
                            <StyledRelation label={relationships[0].relationship} />
                        </StyledRelGroup> */}
                        {/* <StyledRelConnector>
                            <StyledRelationLine />
                        </StyledRelConnector> */}
                        <StyledRelGroup>
                            <Avatar name={relationships[1].firstName} />
                            <StyledRelBox>
                                <StyledRelName label={relationships[1].firstName + ' ' + relationships[1].lastName} />
                            </StyledRelBox>
                            <StyledRelationTypography label={relationships[1].relationship} />
                        </StyledRelGroup>
                    </>
                ) : (
                    <StyledFlexRelTreeContainer>
                        {relationships.map((rel, index) => {
                            return (
                                <StyledRelGroup key={`relationship-${index}`}>
                                    <Avatar size="small" name={rel.firstName} />
                                    <StyledRelBox>
                                        <StyledRelName label={rel.firstName + ' ' + rel.lastName} />
                                    </StyledRelBox>
                                    {rel.relationship === 'Primary' ? (
                                        <StyledPrimaryRelation label={rel.relationship} />
                                    ) : (
                                        <StyledRelationTypography label={rel.relationship} />
                                    )}
                                </StyledRelGroup>
                            );
                        })}
                    </StyledFlexRelTreeContainer>
                )}
            </StyledRelTreeContainer>
        );

    if (onSubmit)
        return (
            <StyledRelTreeContainer>
                {/* Avatar atom needs to be updated to accomodate logged in user's profile image*/}
                {loggedInUserName ? (
                    // Commented for future purpose incase of multiple relationships
                    // <StyledRelGroup>
                    //     <Avatar name={loggedInUserName || ''} />
                    //     <StyledAddRelationshipTypography label="Add Relationship" />
                    //     <StyledIconContainer iconName={<AddOutlined />} onClick={onSubmit} bordered={true} />
                    // </StyledRelGroup>
                    <StyledAddRelGroup>
                        <StyledIconButton iconName={<StyledAddOutlined />} bordered={true} onClick={onSubmit} />
                        <StyledRelLinkedText label={t('CLIENT_PROFILE_RELATIONSHIP_LINK_TEXT')} onClick={onSubmit} />
                        <StyledRelSubHeading label={t('CLIENT_PROFILE_RELATIONSHIP_SUBHEADING')} />
                    </StyledAddRelGroup>
                ) : (
                    <StyledAddRelGroup>
                        <StyledIconButton iconName={<StyledAddOutlined />} bordered={true} onClick={onSubmit} />
                        <StyledRelLinkedText label={t('CLIENT_PROFILE_RELATIONSHIP_LINK_TEXT')} onClick={onSubmit} />
                        <StyledRelSubHeading label={t('CLIENT_PROFILE_RELATIONSHIP_SUBHEADING')} />
                    </StyledAddRelGroup>
                )}
            </StyledRelTreeContainer>
        );

    return <></>;
};
