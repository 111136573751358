import { useLayoutEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { advisorConsoleActions, getAdvisorConsoleProposals } from '../../features/advisorConsole';
import { EmptyClients } from '../../components/organisms/EmptyClients';
import { Spinner } from '../../components/atoms/Spinner/Spinner';
import { AdvisorConsole } from '../../components/organisms/AdvisorConsole';
import { initializeAdviceClient } from '../../features/client';
import { initializeGoals } from '../../features/client-goals';
import { useNavigate } from 'react-router-dom';

const Clients = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { tenant, advisorConsole } = useAppSelector((state) => ({
        tenant: state?.global?.globalConfig?.subdomain,
        advisorConsole: state?.advisorConsole
    }));

    useLayoutEffect(() => {
        dispatch(getAdvisorConsoleProposals({ tenant }));
    }, []);

    const { loading = false, items = [], pagination, filters, hasFilters, clients = [] } = advisorConsole || {};

    if (loading) {
        return <Spinner enabled />;
    }

    if (!items?.length && !hasFilters) {
        return <EmptyClients />;
    }

    const scrollOnChange = (changeAction: () => void) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });

        return changeAction();
    };

    const navigateToCreateClient = () => {
        // initialize page upon landing on /createclient
        dispatch(initializeAdviceClient());
        dispatch(initializeGoals());
        navigate('/createclient');
    };

    return (
        <AdvisorConsole
            clients={clients}
            items={items}
            pagination={pagination}
            onChangePage={(page) => scrollOnChange(() => dispatch(advisorConsoleActions.changePage(page)))}
            onChangeItemsPerPage={(itemsPerPage) =>
                scrollOnChange(() => dispatch(advisorConsoleActions.changeItemsPerPage(itemsPerPage)))
            }
            filters={filters}
            onChangeFilters={(filters) => dispatch(advisorConsoleActions.setFilters(filters))}
            navigateToCreateClient={navigateToCreateClient}
        />
    );
};

export default Clients;
