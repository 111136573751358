import { isEmpty, isUndefined } from 'lodash';
import moment from 'moment';
import { format, isValid, parseISO } from 'date-fns';
import { ReRunOptionType } from '../common/types';
import store from '../redux/store';

const currentState = store.getState();

export function getInitials(name: string): string {
    let initials;
    const splittedName: any = name.split(' ');

    if (!isUndefined(splittedName) && splittedName.length > 1) {
        initials = splittedName.shift().charAt(0) + splittedName.pop().charAt(0);
    } else {
        initials = name.substring(0, 2);
    }
    return initials.toUpperCase();
}

export function deepEqual(obj1:any, obj2:any) {
    // Check if both are objects and not null
    if (typeof obj1 === 'object' && obj1 !== null && 
        typeof obj2 === 'object' && obj2 !== null) {
        
        const keys1 = Object.keys(obj1).sort();
        const keys2 = Object.keys(obj2).sort();

        // Check if keys length is the same
        if (keys1.length !== keys2.length) {
            return false;
        }

        // Check keys and values
        for (let key of keys1) {
            if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
                return false;
            }
        }
        return true; // Objects are equal
    } else {
        // If not objects, do a strict comparison
        return obj1 === obj2;
    }
}

export const getCurrentYear = () => {
    const year = moment().year();

    return year.toString();
};
// The below mapper needs to be updated to add more countries
const dateCountryMapper: any = {
    US: 'mm/dd/yyyy'
};
export const SHORT_DATE_FORMAT = dateCountryMapper[currentState?.global?.globalConfig?.countryCode] || 'mm/dd/yyyy';

export const isValidDate = (date: string | Date): boolean => {
    if (typeof date === 'string') {
        return isValid(parseISO(date));
    }
    return isValid(date);
};
export const toShortDate = (date: string) => {
    const newDate = new Date(date);
    return isValidDate(newDate) ? format(newDate, SHORT_DATE_FORMAT) : null;
};

export function formatCurrency(number = 0): string {
    let formattedNumber = new Intl.NumberFormat().format(number);

    return formattedNumber;
}
// Fetching currency specs from org data
export const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currentState?.global?.globalConfig?.currencySymbol || 'USD',
    minimumFractionDigits: 0
});

export const spaceToHyphen = (phrase: string) => {
    let hyphenatedString = '';
    if (!isEmpty) {
        hyphenatedString = phrase.split('').join('-');
    }
    return hyphenatedString;
};

export const formatCurrencyOrYearWithPlus = (optionValue: ReRunOptionType['optionValue']) => {
    if (typeof optionValue === 'number') return `+${formatter.format(optionValue)}`;
    return `+${optionValue}`;
};

export const formatCurrencyOrYearWithMinus = (optionValue: ReRunOptionType['optionValue']) => {
    if (typeof optionValue === 'number') return `-${formatter.format(optionValue)}`;
    return `-${optionValue}`;
};

export const formatCurrencyDollar = (num: any, doNotAllowZero = true) => {
    let value = num.toString();
    value = value.replace(/[^.0-9]/g, '');

    if (doNotAllowZero) {
        value = value.replace(/\b0+/g, '');
    }

    let splittedValue = value.split('.');

    if (splittedValue[1]?.length > 2) {
        splittedValue[1] = splittedValue[1][0] + splittedValue[1][1];
        value = [splittedValue[0], splittedValue[1]].join('.');
    }

    if (splittedValue?.length > 2) {
        value = [splittedValue[0], splittedValue[1]].join('.');
    }

    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return '$' + (value || 0);
};

export const removeNonNumeric = (num: any) => {
    return num?.toString()?.replace(/[^.0-9]/g, '');
};
export const numberWithCommas = (x: any) => x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
