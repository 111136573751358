import { Tabs } from '../../components/molecules';
import GoalSummary from './GoalSummary';
import { TabVariant } from '../../components/molecules/Tabs/Tabs';
import { useAppSelector } from '../../common/hooks';
import { GoalTitleEnum, GoalTypeEnum, GoalTypeTitlemapping } from '../../common/types';
import { cloneDeep } from 'lodash';
import { useState } from 'react';

const ReviewPlanDetails = ({ updateStep }: any) => {
    const adviceGoalsForms = useAppSelector((state) => state.adviceGoals.formFields);
    const adviceGoals = cloneDeep(useAppSelector((state) => state.adviceGoals));
    const [tabIndex, setTabIndex] = useState('0');
    const global = cloneDeep(useAppSelector((state) => state.global));
    const mappedGoals = adviceGoalsForms.map((item: any, index) => {
        const goalJson = adviceGoals?.response?.filter((itemResponse: any) => item.goalId == itemResponse?.goalId);
        const goalcalculatorJson = adviceGoals.global?.responseGoalCalculator?.filter(
            (itemResponse: any) => item.goalId == itemResponse.goalId
        );
        const goeConfig = global.goeConfig;
        const goalsDataRequest = adviceGoals?.request?.filter(
            (itemRequest: any) => item.goalId === itemRequest?.goalId
        );

        const goalDataRecomendation = adviceGoals?.recommendation?.filter(
            (itemRequest: any) => item.goalId === itemRequest.goalId
        );

        const goalsData = goalsDataRequest[0];
        const goalPriorityValue = goeConfig['pipe_config']['goal_priority_prob_list'];
        const analysisReport = adviceGoals?.response?.find(
            (itemResponse: any) => item.goalId == itemResponse?.goalId
        )?.['response']?.['body']?.['analysisReport'];
        const needFixFlag =
            analysisReport?.['currentGoalProbability'] * 100 <
            goalPriorityValue?.[goalsData?.['goalPriority'] as 'Dream' | 'Want' | 'Wish' | 'Need'] * 100
                ? true
                : false;
        return {
            children: (
                <GoalSummary
                    goalJson={goalJson}
                    goalsData={goalsData}
                    goeConfig={goeConfig}
                    goalcalculatorJson={goalcalculatorJson}
                    goalName={item.data.goalName}
                    goalType={item.data.wealthGoalType}
                    goalId={item.goalId}
                    goalIndex={index}
                   type = { GoalTypeTitlemapping[item.data.wealthGoalType as GoalTitleEnum] as GoalTypeEnum}
                    isGoalRealistic={analysisReport?.['isGoalRealistic']}
                    isRetirement={item.data.goalType === 'WEALTH_GOAL' ? false : true}
                    goalDataRecomendation={goalDataRecomendation}
                    updateStep={updateStep}
                />
            ),
            enabled: true,
            hidden: false,
            type: GoalTypeTitlemapping[item.data.wealthGoalType as GoalTitleEnum] as GoalTypeEnum,
            needFix: needFixFlag,
            title: item.data.goalName
        };
    });

    const handleTabChange = (event: React.SyntheticEvent, value: string) => {
        setTabIndex(value);
        return value;
    };

    return (
        <div>
            <Tabs tabPanels={mappedGoals} value={tabIndex} variant={TabVariant.GOALS_TAB} onChange={handleTabChange} />
        </div>
    );
};

export default ReviewPlanDetails;
