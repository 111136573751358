import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { uipApiInstance } from '../../services';
import { logEventToBackEnd } from '../global/globalSlice';
import { StatusVariant } from '../../components/molecules/AdminTable/Status';
import { API_VERSION } from '../../common/constants';

export type AdviceGoalPayloadType = {
    document: any;
    goeConfigEmail: string;
    _id?: string;
    apiName: string;
    tenant: string;
    apiVer: string;
    domainEmail: string;
    assignedClientId: string;
    formFields: any[];
    request: any[];
    response: any[];
    isMultiGoal: boolean;
    iwsApplied: boolean;
    riskProfile?: string;
    loading: boolean;
    error: null | undefined | any;
    recommendation: any;
    global: {
        banner?: {
            visible: boolean;
            type?: 'attention' | 'success';
            isGoalSummary?: boolean;
            message?: string;
        };
        editMode?: boolean;
        status?: StatusVariant;
        probabilityOfSuccess?: number;
        equityFixedIncome?: string;
        responseGoalCalculator?: any[];
    };
};
const initialState: AdviceGoalPayloadType = {
    _id: '',
    apiName: '',
    tenant: '',
    apiVer: API_VERSION,
    domainEmail: '',
    assignedClientId: '',
    formFields: [],
    request: [],
    response: [],
    isMultiGoal: false,
    iwsApplied: false,
    riskProfile: '',
    loading: false,
    error: '',
    goeConfigEmail: '',
    document: undefined,
    recommendation: [],
    global: {
        banner: {
            visible: false
        },
        editMode: false,
        status: 'draft',
        responseGoalCalculator: []
    }
};

const adviceGoals = createSlice({
    name: 'adviceCreateGoal',
    initialState,
    reducers: {
        initializeGoals: (state) => ({ ...initialState, tenant: state.tenant }),
        syncAdviceGoals: (state, action: PayloadAction<any>) => {
            state = action.payload;
            return state;
        },
        showLoader: (state) => {
            state.loading = true;
        },
        removeLoader: (state) => {
            state.loading = false;
        },
        updateBanner: (state, action: PayloadAction<any>) => {
            state.global.banner = action.payload;
        },
        updateEditStatus: (state, action: PayloadAction<any>) => {
            state.global.editMode = action.payload;
        },
        handleGoalCalculatorResponse: (state, action: PayloadAction<any>) => {
            state.global.responseGoalCalculator = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(createGoal.pending, (state) => {
            state.loading = true;
        }),
            builder.addCase(createGoal.fulfilled, (state, action) => {
                const response = action.payload;
                // eslint-disable-next-line no-unused-vars
                const { __meta, ...rest } = response.document ? response.document : response;
                state._id = rest._id;
                state.apiName = rest.api_name;
                state.response = rest.response;
                state.request = rest.request;
                state.formFields = rest.form_fields;
                state.error = '';
                state.assignedClientId = rest.assignedClientId;
                state.domainEmail = rest.domainEmail;
                state.apiVer = rest.api_ver;
                state.isMultiGoal = rest.is_multi_goal;
                state.iwsApplied = rest.iws_applied;
                state.riskProfile = rest.risk_profile;
                state.recommendation = rest.recommendation;
            }),
            builder.addCase(createGoal.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export const createGoal = createAsyncThunk('advice/creategoals', async (data: any, { dispatch, rejectWithValue }) => {
    try {
        const response = await uipApiInstance({
            method: 'POST',
            url: '/api/advice/createproposals',
            withCredentials: false,
            data
        });
        dispatch(logEventToBackEnd('GOAL_CREATION_SUCCESS'));
        if (response?.data?.document) {
            const doc = response?.data?.document;
            const transformedData = {
                _id: doc._id,
                apiName: doc.api_name,
                tenant: doc.tenant,
                apiVer: doc.api_ver,
                assignedClientId: doc.assignedClientId,
                formFields: doc.form_fields,
                request: doc.request,
                response: doc.response,
                isMultiGoal: doc.is_multi_goal,
                iwsApplied: doc.iws_applied,
                recommendation: doc.recommendation,
                // riskProfile: doc.riskProfile, not returned
                // domainEmail: doc.domainEmail, not returned
                loading: false,
                error: '',
                goeConfigEmail: doc.goeConfigEmail
            };
            dispatch(syncAdviceGoals(transformedData));
        }
        return response?.data?.document;
    } catch (err) {
        console.log(err);
        dispatch(logEventToBackEnd('GOAL_CREATION__ERROR'));
        return rejectWithValue(err);
    }
});

export const updateGoal = createAsyncThunk(
    'advice/updateproposals',
    async (data: any, { dispatch, rejectWithValue }) => {
        try {
            const response = await uipApiInstance({
                method: 'POST',
                url: '/api/advice/updateproposals',
                withCredentials: false,
                data
            });
            dispatch(logEventToBackEnd('GOAL_UPDATE_SUCCESS'));

            if (response?.data?.document) {
                const doc = response?.data?.document;

                const recommendationArr: any[] = [];

                if (doc.form_fields) {
                    doc.form_fields.map((goal: any) => {
                        recommendationArr.push({
                            goalId: goal.goalId,
                            orginalAmount: goal.goalAmount,
                            oneTimeTopUp: 0,
                            topUpAccumulation: 0,
                            toUpDeccumulation: 0,
                            recomendedTenure: 0
                        });
                    });
                }
                const transformedData = {
                    _id: doc._id,
                    apiName: doc.api_name,
                    tenant: doc.tenant,
                    apiVer: doc.api_ver,
                    assignedClientId: doc.assignedClientId,
                    formFields: doc.form_fields,
                    request: doc.request ?? [],
                    response: doc.response ?? [],
                    isMultiGoal: doc.is_multi_goal,
                    iwsApplied: doc.iws_applied,
                    recommendation: recommendationArr,
                    // riskProfile: doc.riskProfile, not returned
                    // domainEmail: doc.domainEmail, not returned
                    loading: false,
                    error: '',
                    goeConfigEmail: doc.goeConfigEmail,
                    global: doc.global ?? {}
                };
                dispatch(syncAdviceGoals(transformedData));
            }
            return response?.data?.document;
        } catch (err) {
            console.log(err);
            dispatch(logEventToBackEnd('GOAL_CREATION__ERROR'));
            return rejectWithValue(err);
        }
    }
);

export const {
    initializeGoals,
    syncAdviceGoals,
    showLoader,
    removeLoader,
    updateEditStatus,
    updateBanner,
    handleGoalCalculatorResponse
} = adviceGoals.actions;

export default adviceGoals.reducer;
