import Highcharts, { SeriesOptionsType } from 'highcharts/highcharts';
import HighchartsReact, { HighchartsReactRefObject } from 'highcharts-react-official';
import { useRef } from 'react';
import highchartsMore from 'highcharts/highcharts-more';
import { PATPalette } from '../../../themes/palette';
import './styles.scss';

// Enables the tag <use> from Icon component
Highcharts.AST.allowedTags.push('use');

highchartsMore(Highcharts);

export type AllocationsOverTimeChartProps = {
    startingYear: number;
    equity: Array<number>;
    fixedIncome: Array<number>;
    alternatives: Array<number>;
    cash: Array<number>;
};
// Type any to allow series options type constraints
const seriesColorMapper: any = {
    Cash: {
        color: PATPalette.secondary.teal[500],
        lineColor: PATPalette.secondary.teal[400]
    },
    Equity: {
        color: PATPalette.secondary.teal[100],
        lineColor: PATPalette.primary.teal
    },
    'Fixed Income': {
        color: PATPalette.secondary.purple[100],
        lineColor: PATPalette.primary.purple
    },
    Alternatives: {
        color: PATPalette.secondary.skyBlue[500],
        lineColor: PATPalette.secondary.skyBlue[300]
    }
};

export const AllocationsOverTimeChart = ({
    startingYear,
    equity,
    fixedIncome,
    alternatives,
    cash
}: AllocationsOverTimeChartProps) => {
    const chartComponentRef = useRef<HighchartsReactRefObject | null>(null);
    const hasAllZeros = (arr: Array<number>) => arr.reduce((val, sum) => val + sum, 0) === 0;
    const generateSeries = () => {
        let series: Array<SeriesOptionsType> = [];
        if (!hasAllZeros(cash))
            series.push({
                type: 'area',
                name: 'Cash',
                data: cash,
                color: PATPalette.secondary.teal[500],
                lineColor: PATPalette.secondary.teal[400],
                lineWidth: 2,
                marker: {
                    enabled: false
                }
            });
        if (!hasAllZeros(alternatives))
            series.push({
                name: 'Alternatives',
                type: 'area',
                data: alternatives,
                color: PATPalette.secondary.skyBlue[500],
                lineColor: PATPalette.secondary.skyBlue[300],
                marker: {
                    enabled: false
                }
            });

        if (!hasAllZeros(fixedIncome))
            series.push({
                type: 'area',
                name: 'Fixed Income',
                data: fixedIncome,
                color: PATPalette.secondary.purple[100],
                lineColor: PATPalette.primary.purple,
                marker: {
                    enabled: false
                }
            });

        if (!hasAllZeros(equity))
            series.push({
                type: 'area',
                name: 'Equity',
                data: equity,
                fillColor: PATPalette.secondary.teal[100],
                lineColor: PATPalette.primary.teal,
                marker: {
                    enabled: false,
                    states: {
                        hover: {
                            enabled: true
                        }
                    },
                    symbol: 'circle',
                    fillColor: '#fff',
                    lineColor: PATPalette.secondary.skyBlue[300],
                    lineWidth: 2,
                    radius: 3
                }
            });

        return series;
    };
    const options: Highcharts.Options = {
        chart: {
            type: 'area'
        },
        title: {
            text: ''
        },
        xAxis: {
            crosshair: {
                dashStyle: 'Dash',
                color: PATPalette.neutral.grey[400],
                width: 1,
                zIndex: 10
            },
            lineWidth: 0,
            left: 65,
            min: startingYear,
            allowDecimals: false,
            tickColor: 'rgba(0,0,0,0.5)',
            tickPosition: 'outside',
            labels: {
                style: labelStyle
            }
        },
        yAxis: {
            allowDecimals: false,
            crosshair: false,
            gridLineWidth: 0,
            tickmarkPlacement: 'between',
            tickWidth: 1,
            tickInterval: 10,
            tickColor: 'rgba(0,0,0,0.5)',
            tickPosition: 'outside',
            title: {
                text: null
            },
            labels: {
                distance: 20,
                formatter: function () {
                    if ((Number(this.value) / 10) % 2 === 0) return `${this.value}%`;
                    return '';
                },
                style: labelStyle
            },
            minPadding: 0,
            maxPadding: 0
        },
        tooltip: {
            shared: true,
            outside: true,
            useHTML: true,
            followPointer: false,
            followTouchMove: false,
            backgroundColor: 'transparent',
            borderWidth: 0,

            formatter: function () {
                const { key: year } = this;
                // TODO: Migrate it to a component
                return `
        <div class="tooltip-chart-card">
            <strong class="title">YEAR ${year}</strong>
            <p class="name">Total allocations</p>
            <div>
                ${
                    hasAllZeros(equity)
                        ? ''
                        : `<div class="legend">
                <div class="left">
                    <div
                    class="bar"
                    style="background: ${PATPalette.secondary.teal[100]}; border: 1px solid ${PATPalette.primary.teal}"
                    ></div>
                    <span class="name">Equity</span>
                </div>

                <strong class="amount">${equity[Number(this.x) - startingYear] || 0}%</strong>
                </div>`
                }

                ${
                    hasAllZeros(fixedIncome)
                        ? ''
                        : `<div class="legend">
                <div class="left">
                    <div
                    class="bar"
                    style="background: ${PATPalette.secondary.purple[100]}; border: 1px solid ${
                              PATPalette.primary.purple
                          }"
                    ></div>
                    <span class="name">Fixed Income</span>
                </div>

                <strong class="amount">${fixedIncome[Number(this.x) - startingYear] || 0}%</strong>
                </div>`
                }

                ${
                    hasAllZeros(alternatives)
                        ? ''
                        : `<div class="legend">
                <div class="left">
                    <div
                    class="bar"
                    style="background: ${PATPalette.secondary.skyBlue[100]}; border: 1px solid ${
                              PATPalette.primary.skyBlue
                          }"
                    ></div>
                    <span class="name">Alternatives </span>
                </div>

                <strong class="amount">${alternatives[Number(this.x) - startingYear] || 0}%</strong>
                </div>`
                }
                ${
                    hasAllZeros(cash)
                        ? ''
                        : `<div class="legend" style="margin-bottom: 0">
                <div class="left">
                    <div
                    class="bar"
                    style="background: ${PATPalette.secondary.teal[500]}; border: 1px solid ${
                              PATPalette.secondary.teal[400]
                          }"
                    ></div>
                    <span class="name">Cash </span>
                </div>

                <strong class="amount">${cash[Number(this.x) - startingYear] || 0}%</strong>
                </div>`
                }
            </div>
        </div>
            `;
            }
        },
        plotOptions: {
            series: {
                pointStart: startingYear
            },
            area: {
                stacking: 'percent',
                marker: {
                    enabled: false,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                }
            }
        },
        series: generateSeries(),
        legend: {
            enabled: true,
            align: 'right',
            verticalAlign: 'top',
            itemMarginBottom: 1,
            reversed: true,
            symbolPadding: 0,
            symbolWidth: 0,
            symbolHeight: 0,
            squareSymbol: false,
            accessibility: {
                enabled: true,
                keyboardNavigation: {
                    enabled: true
                }
            },
            useHTML: true,
            labelFormatter: function () {
                return `
                <div class="legend-cont">
                    <div
                    class="legend-bar"
                    style="background: ${seriesColorMapper[this.name].color}; border: 1px solid ${
                    seriesColorMapper[this.name].lineColor
                }"
                    ></div>
                    <p class="legend-name">${this.name} </p>
                </div>`;
            }
        },
        credits: {
            enabled: false
        }
    };

    return <HighchartsReact highcharts={Highcharts} options={options} ref={chartComponentRef} />;
};

const labelStyle = {
    color: PATPalette.neutral.black,
    fontFamily: '"TT Commons Pro"',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '16px',
    letterSpacing: '0.12px'
};
