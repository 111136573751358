import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { CLIENT_PROFILE_STAGE_KEYS } from '../../common/constants';
import { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { hex2rgba } from '../../common/helper';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import personalInfoIcon from '../../assets/icons/personalinfoicon.svg';
import { createClient, updateClient } from '../../features/client';
import { BasicButton, BasicDatePicker, ComboSelect, TextboxInput } from '../../components/atoms';
import { DrawerComp } from '../../components/molecules';
import { ReactComponent as AccountCircle } from '../../assets/icons/account_circle.svg';
import { customPalette, PATPalette } from '../../themes/palette';
import { RelationshipProps, RelationshipsList } from './RelationshipsList';
// import { Add } from '@mui/icons-material';      // Commented for future purpose incase of multiple relationships

export const GridContainer: any = styled(Grid)(() => ({
    '.MuiInputBase-input': {
        boxSizing: 'border-box'
    },
    minHeight: '100vh'
}));

export const LeftContainer: any = styled(GridContainer)(({ theme }) => ({
    '.MuiInputBase-input': {
        boxSizing: 'border-box'
    },
    backgroundColor: theme.palette.layout.primary,
    alignItems: 'flex-start',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 10%'
}));

export const RightContainer: any = styled(GridContainer)(({ theme }) => ({
    '.MuiInputBase-input': {
        boxSizing: 'border-box'
    },
    background: `${theme.palette.layout.primary} 0.5`,
    backgroundColor: `${hex2rgba(theme.palette.layout.primary, 0.5)}`,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px'
}));

export const ErrorSpan = styled(Typography)(() => ({
    color: 'red',
    display: 'flex',
    marginTop: '5px'
}));

export const ItemContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    '& a': {
        color: theme.palette.background.paper
    }
}));

export const FormContainer: any = styled(GridContainer)(({ theme }) => ({
    '.MuiInputBase-input': {
        boxSizing: 'border-box'
    },
    backgroundColor: theme.palette.layout.white,
    padding: 30,
    borderRadius: 10,
    marginTop: '20px',
    maxWidth: '330px',
    minHeight: '75vh',
    border: '1px solid'
}));

export const Button = styled(BasicButton)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    minWidth: '35%',
    color: theme.palette.primary.main,
    textTransform: 'capitalize'
}));

export const WelcomText = styled(Typography)(({ theme }) => ({
    color: theme.palette.background.default
}));

const StyledAddNewRelationshipTitle = styled(Typography)(() => ({
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 600,
    margin: '16px 0'
}));

const StyledInfoText = styled(Typography)(() => ({
    fontSize: '16px',
    lineHeight: '12px',
    fontWeight: 400,
    fontStyle: 'italic'
}));

const StyledDrawerBodyContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
}));

const StyledBasicDatePicker = styled(BasicDatePicker)(({ theme }) => ({
    margin: '24px',
    '& .MuiIconButton-root': {
        borderRadius: '50%',
        padding: '4px',
        background: theme.palette.primary.light,
        '& svg': {
            color: theme.palette.primary.dark
        }
    }
}));
// Commented for future purpose incase of multiple relationships
// const StyledIconButton = styled(IconButton)(() => ({
//     padding: '10px',
//     width: '100%',
//     border: `1px solid ${PATPalette.neutral.grey[300]}`,
//     '& svg': {
//         width: '18px',
//         height: '18px',
//         color: PATPalette.neutral.grey[300]
//     },
//     '& p': {
//         fontSize: '16px',
//         fontWeight: 600,
//         lineHeight: '20px',
//         color: PATPalette.neutral.grey[300],
//         margin: 0
//     }
// }));

const StyledRequiredText = styled(Typography)(() => ({
    variant: 'body1',
    fontStyle: 'italic',
    color: customPalette.universalCard.subTextFontColor
}));
interface FormInput {
    fullname: string;
    birthMonth: Dayjs | null;
    birthYear: Dayjs | null;
    relationship: string;
}

export const PersonalInfoAndRelationshipsDrawer = ({ isRelationship, openDrawer, setOpenDrawer }: any) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const adviceClient = useAppSelector((state) => state.adviceClient);
    const { loading, error, relationship } = adviceClient;
    const [serverError, setServerError] = useState({});
    const { orgInfo } = useAppSelector((state) => state.global);
    const RELATIONSHIP_DATA = orgInfo?.relationshipTypes?.en;
    const [relationshipinfo, setRelationshipInfo] = useState(RELATIONSHIP_DATA);
    const [relationships] = useState(adviceClient['relationship']);
    const [keyId] = useState(uuidv4);

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset
    } = useForm<FormInput>({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            fullname: '',
            birthMonth: null as Dayjs | null,
            birthYear: null as Dayjs | null,
            relationship: isRelationship ? '' : 'Primary'
        }
    });

    useEffect(() => {
        if (isRelationship && relationship.length > 0) {
            const relationData = relationship[0];
            if (relationData.relationship == 'Partner' || relationData.relationship == 'Spouse') {
                const filteredData = relationshipinfo.filter(
                    (item: typeof RELATIONSHIP_DATA) => item.value != 'Spouse' && item.value != 'Partner'
                );
                setRelationshipInfo(filteredData);
            }
        }
    }, [relationship.length > 0]);

    var originalObject = JSON.parse(JSON.stringify(adviceClient));
    const keysToRemove = ['loading', 'error'];
    const updatedObject = Object.assign({}, originalObject);
    keysToRemove.forEach((key) => delete updatedObject[key]);
    const onSubmit = async (values: FormInput) => {
        let month = values.birthMonth?.toDate()?.getMonth() ? values.birthMonth?.toDate()?.getMonth() + 1 : 1;
        let year = values.birthYear?.toDate()?.getFullYear();
        const finalDate = '01-' + (month > 9 ? month : '0' + month) + '-' + year;
        if (isRelationship) {
            const payloadData = {
                firstName: values.fullname,
                lastName: '',
                age: finalDate,
                relationship: values.relationship
            };
            var originalObject = JSON.parse(JSON.stringify(adviceClient));
            const keysToRemove = ['loading', 'error'];
            const updatedObject = Object.assign({}, originalObject);
            keysToRemove.forEach((key) => delete updatedObject[key]);
            updatedObject['relationship'] = [...updatedObject['relationship'], ...relationships, payloadData];
            (updatedObject['stages'] = [...adviceClient?.stages, CLIENT_PROFILE_STAGE_KEYS[2]]),
                await dispatch(updateClient(updatedObject));
        } else {
            const payloadData = {
                clientFirstName: values.fullname,
                clientLastName: '',
                clientDob: finalDate,
                relationship: [],
                tenant: adviceClient?.tenant,
                accounts: adviceClient?.accounts,
                stages: [...adviceClient?.stages, CLIENT_PROFILE_STAGE_KEYS[1], CLIENT_PROFILE_STAGE_KEYS[2]],
                riskProfile: adviceClient?.riskProfile,
                step: updatedObject.step
            };
            reset({ birthMonth: null as Dayjs | null, birthYear: null as Dayjs | null, relationship: '' });
            await dispatch(createClient(payloadData));
        }
    };
    // Commented for future purpose incase of multiple relationships
    // const onAddAnotherRelationship = async (values: FormInput) => {
    //     let month = values.birthMonth?.toDate()?.getUTCMonth() ? values.birthMonth?.toDate()?.getUTCMonth() + 1 : 1;
    //     let year = values.birthYear?.toDate()?.getUTCFullYear();
    //     const finalDate = month + '-' + year;
    //     const payloadData = {
    //         firstName: values.fullname,
    //         lastName: '',
    //         age: finalDate,
    //         relationship: values.relationship
    //     };
    //     const currentRelationships = [...relationships];
    //     currentRelationships.push(payloadData);
    //     setRelationships(currentRelationships);
    //     reset();
    //     setKeyId(uuidv4());
    // };

    const persistUnsavedRelationships = async () => {
        try {
            var originalObject = JSON.parse(JSON.stringify(adviceClient));
            const keysToRemove = ['loading', 'error'];
            const updatedObject = Object.assign({}, originalObject);
            keysToRemove.forEach((key) => delete updatedObject[key]);
            updatedObject['relationship'] = [...updatedObject['relationship'], ...relationships];
            (updatedObject['stages'] = [...adviceClient?.stages, CLIENT_PROFILE_STAGE_KEYS[2]]),
                await dispatch(updateClient(updatedObject));
            reset();
        } catch (error) {
            console.error('Error in adding relationships', error);
        }
    };

    const checkUnsavedAndhandleClose = async (flag: boolean) => {
        if (relationships?.length > 0) await persistUnsavedRelationships();
        setOpenDrawer(flag);
    };

    const resetChild = () => {
        reset(
            isRelationship
                ? { birthMonth: null as Dayjs | null, birthYear: null as Dayjs | null, relationship: '' }
                : { birthMonth: null as Dayjs | null, birthYear: null as Dayjs | null, relationship: 'Primary' }
        );
        setServerError('');
    };

    useEffect(() => {
        setServerError(error);
        if (error) {
            setOpenDrawer(true);
        } else {
            // resetChild()
            setOpenDrawer(false);
        }
    }, [error]);

    useEffect(() => {
        setRelationshipInfo(
            isRelationship
                ? RELATIONSHIP_DATA.filter((item: typeof RELATIONSHIP_DATA) => item.value != 'Primary')
                : RELATIONSHIP_DATA
        );
        reset(
            isRelationship
                ? { birthMonth: null as Dayjs | null, birthYear: null as Dayjs | null, relationship: '' }
                : { birthMonth: null as Dayjs | null, birthYear: null as Dayjs | null, relationship: 'Primary' }
        );
    }, [isRelationship]);

    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1;
    // let dd = today.getDate();

    // dd = dd < 10 ? dd : Number('0' + dd);
    mm = mm < 10 ? mm : Number('0' + mm);

    const formattedToday = yyyy - 18 + '/' + mm;
    const formattedBirth = yyyy - 117 + '/' + mm;
    const dataContainer = (
        <>
            {isRelationship ? (
                <>
                    <RelationshipsList
                        relationshipsData={relationships.reduce((rel: Record<string, RelationshipProps>, curr) => {
                            rel[curr.firstName] = {
                                firstName: curr.firstName,
                                relationship: curr.relationship,
                                isSelected: false
                            };
                            return rel;
                        }, {})}
                        title="Saved relationships"
                    />
                    <StyledAddNewRelationshipTitle>{t('ADD_NEW_RELATIONSHIP')}</StyledAddNewRelationshipTitle>
                    <StyledInfoText>{t('TEXT_ALL_REQUIRED_FIELDS')}</StyledInfoText>
                </>
            ) : (
                <Grid paddingTop={0}>
                    <StyledRequiredText>{t('TEXT_ALL_REQUIRED_FIELDS')}</StyledRequiredText>
                </Grid>
            )}
            <form onSubmit={handleSubmit(onSubmit)} key={keyId}>
                <ItemContainer sx={{ alignItems: 'flex-start', width: '100%' }}>
                    <Typography variant="body1">Full Name</Typography>
                    <Controller
                        name="fullname"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: {
                                value: true,
                                message: t('TEXT_THIS_IS_REQUIRED_FIELD_FULLNAME')
                            },
                            pattern: {
                                value: /^[0-9A-Za-zñáéíóúü_ ]*$/i,
                                message: t('TEXT_FULLNAME_VALIDATION')
                            }
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <TextboxInput
                                onBlur={onBlur}
                                onChange={onChange}
                                value={value}
                                inputRef={ref}
                                error={!!errors.fullname}
                                type="text"
                                data-testid="Last-Middle-First"
                                // placeholder="Last, Middle, First"  AEX-1293
                                fullWidth
                                autoFocus
                            />
                        )}
                    />
                    {errors.fullname ? (
                        <ErrorSpan variant="subtitle2" role="alert">
                            {errors.fullname.message}
                        </ErrorSpan>
                    ) : null}
                </ItemContainer>
                <ItemContainer sx={{ alignItems: 'flex-start' }}>
                    <Typography variant="body1">{t('BIRTH_MONTH')}</Typography>
                    <Controller
                        name="birthMonth"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: t('TEXT_THIS_IS_REQUIRED_FIELD')
                            }
                        }}
                        defaultValue={undefined}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <StyledBasicDatePicker
                                enabled
                                labelEnd="End Date"
                                labelStart=""
                                startDate={''}
                                type="single"
                                isMm={true}
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                maxErrorMsg={t('MIN_AGE_FIELD_TEXT')}
                                minErrorMsg={t('MAX_AGE_FIELD_TEXT')}
                                data-testid="birthMonth"
                            />
                        )}
                    />
                    {errors.birthMonth ? (
                        <ErrorSpan variant="subtitle2" role="alert">
                            {errors.birthMonth.message}
                        </ErrorSpan>
                    ) : null}
                </ItemContainer>
                <ItemContainer sx={{ alignItems: 'flex-start' }}>
                    <Typography variant="body1">{t('BIRTH_YEAR')}</Typography>
                    <Controller
                        name="birthYear"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: t('TEXT_THIS_IS_REQUIRED_FIELD')
                            }
                        }}
                        defaultValue={undefined}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <StyledBasicDatePicker
                                enabled
                                endDate={formattedToday}
                                labelEnd="End Date"
                                labelStart=""
                                startDate={formattedBirth}
                                type="single"
                                isYyyy={true}
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                maxErrorMsg={t('MIN_AGE_FIELD_TEXT')}
                                minErrorMsg={t('MAX_AGE_FIELD_TEXT')}
                                data-testid="birthYear"
                                slotProps={{
                                    openPickerButton: {
                                        color: PATPalette.secondary.skyBlue[100]
                                    }
                                }}
                            />
                        )}
                    />
                    {errors.birthYear ? (
                        <ErrorSpan variant="subtitle2" role="alert">
                            {errors.birthYear.message}
                        </ErrorSpan>
                    ) : null}
                </ItemContainer>
                <ItemContainer sx={{ alignItems: 'flex-start', marginBottom: '16px' }}>
                    <Typography variant="body1">{t('RELATIONSHIP')}</Typography>
                    <Controller
                        name="relationship"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: t('TEXT_THIS_IS_REQUIRED_FIELD')
                            }
                        }}
                        defaultValue=""
                        render={({ field }) => (
                            <ComboSelect
                                {...field}
                                onSelectChange={field.onChange}
                                selectedValue={isRelationship ? field.value : 'Primary'}
                                options={relationshipinfo}
                                disabled={isRelationship ? false : true}
                                data-testid="relationship"
                                error={!!errors.relationship}
                            />
                        )}
                    />
                    {errors.relationship ? (
                        <ErrorSpan variant="subtitle2" role="alert">
                            {errors.relationship.message}
                        </ErrorSpan>
                    ) : null}
                </ItemContainer>
                {/* Commented for future purpose */}
                {/* {isRelationship && (
                    <Box sx={{ margin: '16px 0' }}>
                        <StyledIconButton
                            iconName={
                                <>
                                    <Add />
                                    <Typography>{t('ADD_ANOTHER_RELATIONSHIP')}</Typography>
                                </>
                            }
                            bordered
                            onClick={handleSubmit(onAddAnotherRelationship)}
                        />
                    </Box>
                )} */}
            </form>
        </>
    );

    return (
        <>
            {openDrawer && (
                <DrawerComp
                    onSubmit={handleSubmit(onSubmit)}
                    resetChild={resetChild}
                    loading={loading}
                    error={
                        !!errors.fullname ||
                        !!errors.birthMonth ||
                        !!errors.birthYear ||
                        !!errors.relationship ||
                        serverError ||
                        error
                    }
                    header={isRelationship ? t('CREATE_RELATIONSHIP_HEADER_TEXT') : t('CREATE_CLIENT_HEADER_TEXT')}
                    drawerIcon={isRelationship ? <AccountCircle /> : <img src={personalInfoIcon} />}
                    open={openDrawer}
                    handleClose={checkUnsavedAndhandleClose}
                >
                    <StyledDrawerBodyContainer>{dataContainer}</StyledDrawerBodyContainer>
                </DrawerComp>
            )}
        </>
    );
};
