import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { uipApiInstance } from '../../services';
import { CLIENT_PROFILE_STAGE_KEYS } from '../../common/constants';
import { AdviceClientType, AdviceClientType as InitialState } from '../../common/types';
import { logEventToBackEnd } from '../../features/global/globalSlice';

const initialState: InitialState = {
    _id: '',
    clientDob: '',
    clientFirstName: '',
    clientLastName: '',
    tenant: '',
    relationship: [],
    riskProfile: '',
    accounts: { incomeSources: [], investmentsSources: [] },
    stages: [CLIENT_PROFILE_STAGE_KEYS[0]],
    loading: false,
    error: '',
    step: 0, // tracks the current step of the client creation process. (on the Stepper)
    editMode: false
};

const adviceClient = createSlice({
    name: 'adviceCreateClient',
    initialState,
    reducers: {
        initializeAdviceClient: (state) => ({ ...initialState, tenant: state.tenant }),
        syncAdviceClient: (state, action: PayloadAction<AdviceClientType>) => {
            state = action.payload;
            return state;
        },
        updateClientIncomeSources: (state, action: PayloadAction<any>) => {
            state.accounts.incomeSources = action.payload;
        },
        updateClientInvestmentSources: (state, action: PayloadAction<any>) => {
            state.accounts.investmentsSources = action.payload;
        },
        // We need to keep track of which step we are on in Redux else
        // if we are in step 2 and make any change to the redux store, the whole application
        //re-renders and it moves us to step 1.
        updateCurrentStep: (state, action: PayloadAction<any>) => {
            state.step = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(createClient.pending, (state) => {
            state.loading = true;
        }),
            builder.addCase(createClient.fulfilled, (state, action) => {
                const response = action.payload;
                state._id = response._id;
                state.clientDob = response.clientDob;
                state.clientFirstName = response.clientFirstName;
                state.tenant = response.tenant;
                state.stages = response.stages;
                state.accounts = response.accounts;
                state.relationship = response.relationship;
                state.error = '';
                state.loading = false;
                state.accounts = response.accounts;
                state.riskProfile = response.riskProfile;
                state.step = response.step;
            }),
            builder.addCase(createClient.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            }),
            builder.addCase(updateClient.pending, (state) => {
                state.loading = true;
            }),
            builder.addCase(updateClient.fulfilled, (state) => {
                state.error = '';
                state.loading = false;
            }),
            builder.addCase(updateClient.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export const createClient = createAsyncThunk(
    'advice/createclientprofile',
    async (data: any, { dispatch, rejectWithValue }) => {
        try {
            const response = await uipApiInstance({
                method: 'POST',
                url: '/api/advice/createclientprofile',
                withCredentials: false,
                data
            });
            dispatch(logEventToBackEnd('CREATE_CLIENT_PROFILE_SUCCESS'));
            dispatch(syncAdviceClient(response?.data));
            return response.data;
        } catch (err) {
            console.log(err);
            dispatch(logEventToBackEnd('CREATE_CLIENT_PROFILE_ERROR'));
            return rejectWithValue(err);
        }
    }
);

export const updateClient = createAsyncThunk(
    'advice/updateclientprofile',
    async (data: any, { dispatch, rejectWithValue }) => {
        try {
            const response = await uipApiInstance({
                method: 'POST',
                url: '/api/advice/updateclientprofile',
                withCredentials: false,
                data
            });
            dispatch(logEventToBackEnd('UPDATE_CLIENT_PROFILE_SUCCESS'));
            dispatch(syncAdviceClient(response?.data));
            return response.data;
        } catch (err) {
            console.log(err);
            dispatch(logEventToBackEnd('UPDATE_CLIENT_PROFILE_ERROR'));
            return rejectWithValue(err);
        }
    }
);

export const {
    initializeAdviceClient,
    syncAdviceClient,
    updateClientIncomeSources,
    updateClientInvestmentSources,
    updateCurrentStep
} = adviceClient.actions;

export default adviceClient.reducer;
