import { useMemo } from 'react';
import { AllocationsOverTimeChart } from '../../components/molecules/AllocationsOverTimeChart';
import { useAppSelector } from '../../common/hooks';

export const AllocationsOverTimeTab = ({ portfolioPath }: { portfolioPath: Array<number> }) => {
    const { portfolio: allPortfolios } = useAppSelector((state) => state.global);
    const generateEquityArray = useMemo(
        () =>
            portfolioPath.map((yearPortfolioId) => {
                const { assets } = allPortfolios?.find((p) => p.portfolioId === yearPortfolioId) || { assets: [] };
                return assets.find((asset: any) => asset.type === 'Equity')?.totalAllocatedvalue || 0;
            }),
        [portfolioPath]
    );
    const generateFixedIncomeArray = useMemo(
        () =>
            portfolioPath.map((yearPortfolioId) => {
                const { assets } = allPortfolios.find((p) => p.portfolioId === yearPortfolioId) || { assets: [] };
                return assets.find((asset: any) => asset.type === 'Fixed Income')?.totalAllocatedvalue || 0;
            }),
        [portfolioPath]
    );
    const generateAlternativesArray = useMemo(
        () =>
            portfolioPath.map((yearPortfolioId) => {
                const { assets } = allPortfolios.find((p) => p.portfolioId === yearPortfolioId) || { assets: [] };
                return assets.find((asset: any) => asset.type === 'Alternatives')?.totalAllocatedvalue || 0;
            }),
        [portfolioPath]
    );
    const generateCashArray = useMemo(
        () =>
            portfolioPath.map((yearPortfolioId) => {
                const { assets } = allPortfolios.find((p) => p.portfolioId === yearPortfolioId) || { assets: [] };
                return assets.find((asset: any) => asset.type === 'Cash')?.totalAllocatedvalue || 0;
            }),
        [portfolioPath]
    );
    return (
        <AllocationsOverTimeChart
            startingYear={new Date().getFullYear()} // current year
            equity={[...generateEquityArray, generateEquityArray[generateEquityArray.length - 1]]}
            fixedIncome={[...generateFixedIncomeArray, generateFixedIncomeArray[generateFixedIncomeArray.length - 1]]}
            alternatives={[
                ...generateAlternativesArray,
                generateAlternativesArray[generateAlternativesArray.length - 1]
            ]}
            cash={[...generateCashArray, generateCashArray[generateCashArray.length - 1]]}
        />
    );
};
