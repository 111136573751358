import React, { CSSProperties, MouseEvent, ReactNode } from 'react';
import _get from 'lodash/get';
import _set from 'lodash/set';
import { TypographyVariant } from '../../../common/types/typography';
import { ColorsVariant } from '../../../common/types/colors';
import { PATPalette } from '../../../themes/palette';
import { useTranslation } from 'react-i18next';
import { typography } from '../../../themes';
import { styled } from '@mui/material';

export type TypographyProps = PropsWithTranslation | PropsWithChildren | PropsWithLabel;

export const Typography = ({
    as = 'p',
    color = 'neutral.grey.500',
    display = 'block',
    variant = 'body1',
    sx,
    onClick,
    ...props
}: TypographyProps) => {
    const translation = useTranslation();

    const { t, tProps }: PropsWithTranslation = { ...props };
    const { children }: PropsWithChildren = { ...props };
    const { label }: PropsWithLabel = { ...props };

    return (
        <Component
            variant={variant}
            color={color}
            as={as}
            display={display}
            style={{ ...sx, ...props }}
            onClick={onClick}
            {...props}
            {...(!!t && {
                dangerouslySetInnerHTML: {
                    __html: (translation.t(t!, tProps) as string) || '' // needed so we can use html inside translation file
                }
            })}
        >
            {children || label}
        </Component>
    );
};

const Component = styled('span')<
    {
        variant: TypographyVariant;
        color: ColorsVariant;
    } & DefaultProps
    // eslint-disable-next-line
>(({ variant, color, m, p, theme, display, ...rest }) => ({
    all: 'unset',
    color: _get(PATPalette, color),
    ...(_get(typography, variant!) as object),
    ...(!!m && spacingStyles('m')(m)),
    ...(!!p && spacingStyles('p')(p)),
    ...(rest?.ml !== undefined && {
        marginLeft: rest?.ml
    }),
    ...(rest?.mr !== undefined && {
        marginRight: rest?.mr
    }),
    ...(rest?.mt !== undefined && {
        marginTop: rest?.mt
    }),
    ...(rest?.mb !== undefined && {
        marginBottom: rest?.mb
    }),
    display
}));

// const filterCssProps = (props: any) => Object.keys(props).map((key) => key in CSSProperties);

const spacingStyles = (type: 'm' | 'p') => (spacing: Spacing) => {
    const attrName = type === 'm' ? 'margin' : 'padding';

    const keys = Object.keys(spacing).filter((key) => spacing?.[key as keyof Spacing] !== undefined);

    let obj = {};

    keys.forEach((key) => {
        _set(obj, [attrName, key.charAt(0).toUpperCase(), key.substring(1)].join(''), spacing[key as keyof Spacing]);
    });

    return obj;
};

type DefaultProps = {
    variant?: TypographyVariant;
    color?: ColorsVariant;
    as?: React.ElementType;
    m?: Spacing;
    p?: Spacing;
    display?: string;
    flex?: string | number;
    // eslint-disable-next-line
    onClick?(event: MouseEvent<any>): void;
    marginBottom?: string | number;
    marginLeft?: string | number;
    marginTop?: string | number;
    marginRight?: string | number;

    ml?: string | number;
    mr?: string | number;
    mt?: string | number;
    mb?: string | number;
    paddingBottom?: string | number;

    // Should we continue with these props?
    role?: string;
    tabIndex?: number;
    id?: string;
    cursor?: string;
    sx?: CSSProperties;
};

type PropsWithTranslation = {
    t?: string;
    tProps?: any;
} & DefaultProps;

type PropsWithChildren = {
    children?: ReactNode;
} & DefaultProps;

type PropsWithLabel = {
    label?: ReactNode;
} & DefaultProps;

type Spacing = {
    top?: string | number;
    bottom?: string | number;
    left?: string | number;
    right?: string | number;
};
