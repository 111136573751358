import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ConfirmationDialog } from '../../molecules/ConfirmationDialog/ConfirmationDialog';
import FinancialAdvisorTable from './FinancialAdvisorTable';

interface FinancialAdvisorProps {
    financialAdvisors: any;
    deleteModalState: boolean;
    columns: any;
    handleCloseDeleteModal: () => void;
    advisorSelected: any;
    handleRemoveFinancialAdvisor: () => void;
}

export default function FinancialAdvisor({
    financialAdvisors,
    deleteModalState,
    columns,
    handleCloseDeleteModal,
    advisorSelected,
    handleRemoveFinancialAdvisor
}: FinancialAdvisorProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleNewAdvisor = () => {
        navigate('/add-user', { state: { fromAdvisor: true } });
    };

    return (
        <>
            <FinancialAdvisorTable
                data={financialAdvisors}
                columns={columns}
                button={t('FINANCIAL_ADVISORS_ADD_NEW_ADVISOR')}
                buttonOnClick={handleNewAdvisor}
            />

            <ConfirmationDialog
                onCancellation={handleCloseDeleteModal}
                onConfirmation={() => handleRemoveFinancialAdvisor()}
                open={deleteModalState}
                content={`${t('REMOVE_ADVISOR_MODAL_CONFIRMATION_TEXT', {
                    advisorName: advisorSelected.advisorName
                })}`}
            />
        </>
    );
}
