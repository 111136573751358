import { ButtonProps } from '@mui/material/Button';
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';

export interface BasicButtonProps extends LoadingButtonProps {
    textVariantStyle?: 'capitalize' | 'uppercase' | 'lowercase' | 'none';
    ariaLabel?: string;
}

export const BasicButton = ({
    textVariantStyle = 'none',
    ariaLabel, // added aria label as part of initial accessibility
    ...props
}: BasicButtonProps) => {
    // eslint-disable-next-line no-unused-vars
    const StyledButton = styled(LoadingButton)<ButtonProps>(() => ({
        textTransform: textVariantStyle
    }));

    return (
        <StyledButton aria-label={ariaLabel} {...props}>
            {props.children}
        </StyledButton>
    );
};
